var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.39.0-feature-challenge-screens"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { extraErrorDataIntegration } from '@sentry/integrations'
import { init } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const TRACES_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0
const REPLAYS_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SAMPLE_RATE) || 0
const REPLAYS_ONERROR_SAMPLE_RATE =
  Number(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ONERROR_SAMPLE_RATE) || 0
const NORMALIZE_DEPTH = Number(process.env.NEXT_PUBLIC_SENTRY_NORMALIZE_DEPTH) || 3

init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  sampleRate: 1,
  tracesSampleRate: TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: REPLAYS_SAMPLE_RATE,
  replaysOnErrorSampleRate: REPLAYS_ONERROR_SAMPLE_RATE,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: process.env.APP_VERSION,
  environment: process.env.SENTRY_ENV,
  beforeSend(event, hint) {
    const causeMessage = hint?.originalException?.cause?.message

    if (!causeMessage) {
      return event
    }

    const isNetworkProblem = /^(Failed to fetch|Load failed)$/gi.test(causeMessage)

    if (isNetworkProblem) {
      return null
    }

    return event
  },
  initialScope: {
    tags: {
      'build.date': process.env.APP_BUILD_DATE,
    },
  },
  integrations: [extraErrorDataIntegration()],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // fb/insta browser related issue https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
    'PaymentAutofillConfig',
    '_reportEvent is not defined',
    // paypal integration dependency
    'zoid destroyed all components',
    'Non-Error promise rejection captured with value',
    'Detected container element removed from DOM',
    "Cannot read properties of undefined (reading 'DomainData').",
    /.*undefined is not an object (evaluating 't.default').*/g,
    /.*undefined is not an object (evaluating 't.Domain').*/g,
    /.*undefined is not an object (evaluating 'n.length').*/g,
    /.*Cannot read properties of undefined (reading 'domInteractive').*/g,
    // DuckDuckGo mobile 7 postMessage error
    /.*WKWebView API client did not respond to this postMessage.*/g,
    // Solid SDK Console Error after switching products on payment screen
    /.*Payment form iframe was removed from DOM before initialization ended.*/g,
    /.*Window closed for postrobot_method before ack.*/g,
    /.*Can't find variable: _AutofillCallbackHandler.*/g,
    /.*Failed to fetch.*/g,
    /.*Load failed.*/g,
    'Failed to fetch',
    'Load failed',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /.*fbevents.js.*/i,
    /.*otBannerSDK.js.*/i,
    /.*lst\/universal-script.*/i,
  ],
  normalizeDepth: NORMALIZE_DEPTH,
})
